import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { API } from '@app/shared/app.constant';
import { Observable } from 'rxjs';
import { AuthorizedProfile } from '@app/shared/models/corebusiness/authorized-profile';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedProfileService {

  constructor(
    private apiService: ApiClientService,
  ) { }

  public getShelfIssuer(companyId: any): Observable<AuthorizedProfile[]> {
    const url = API.GET_AUTHORIZED_PROFILE;
    return this.apiService.get(`${url}/${companyId}`).pipe(map((d: any[]) => d.map(item => Object.assign(new AuthorizedProfile(), item))));
  }

  public getSubmitApplicationFull(companyId: any): Observable<AuthorizedProfile[]> {
    const url = API.GET_SUBMIT_APPLICATION_FULL_AUTHORIZED_PROFILE;
    return this.apiService.get(`${url}/${companyId}`).pipe(map((d: any[]) => d.map(item => Object.assign(new AuthorizedProfile(), item))));
  }
}
