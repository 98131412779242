import { UserProfile } from './user-profile'
import { AttachListingDoc } from './attach-listing-doc'
import { StatusCommon } from './status-common.enum'

export enum ShelfIssuerAllowType {
  AU = 'AU',
  MM = 'MM',
}

export class ShelfIssuer {
  id: number
  name: string
  status: StatusCommon
  authorizedPersons: string
  effectiveDate: Date
  expiredDate: Date
  expireDate: Date;
  allowedType: ShelfIssuerAllowType
  authorizedType: string
  signType: string
  createdDate: Date
  modifiedDate: Date
  creator: UserProfile
  modifier: UserProfile
  sender: UserProfile
  approver: UserProfile
  productType: string
  attaches: AttachListingDoc[];
  companySecId: string
  cryptCompanyId: string
  remark: string;
  companyId: number;
}

export const ShelfIssuerStatus = {
  draftInComplete: 'Draft Incomplete',
  readyToSend: 'Ready to Send',
  waitForHardCopy: 'Wait for hard copy',
  secConsidering: 'SEC Considering',
  approved: 'Approved',
  cancelled: 'Cancelled'
}
