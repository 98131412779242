import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShelfIssuerApplicationDetailRoutingModule } from './shelf-issuer-application-routing.module';
import { ShelfIssuerApplicationComponent } from './shelf-issuer-application.component';
import { SharedModule } from '@app/shared/shared.module';
import { AuthorizedPersonGuard } from './authorized-person.guard';

@NgModule({
  declarations: [ShelfIssuerApplicationComponent],
  imports: [
    CommonModule,
    ShelfIssuerApplicationDetailRoutingModule,
    SharedModule,
  ],
  providers: [
    AuthorizedPersonGuard
  ]
})
export class ShelfIssuerApplicationModule { }
