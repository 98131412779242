import { Injectable } from '@angular/core';
import { ShelfIssuer, ShelfIssuerAllowType } from '@app/shared/models/corebusiness/shelf-issuer';
import { StatusCommon } from '@app/shared/models/corebusiness/status-common.enum';

@Injectable()
export class SecAdminShelfIssuerService {
  constructor() { }

  public canApprove(shelfIssuer: ShelfIssuer): boolean {
    return shelfIssuer && [StatusCommon.SEC_CONSIDERING].includes(shelfIssuer.status) && ![ShelfIssuerAllowType.AU].includes(shelfIssuer.allowedType);
  }

  public canRevert(shelfIssuer: ShelfIssuer): boolean {
    return shelfIssuer && [StatusCommon.WAIT_FOR_HARD_COPY].includes(shelfIssuer.status);
  }
}
