import { Injectable } from '@angular/core';
import { StatusCommon } from '@app/shared/models/corebusiness/status-common.enum';
import { UserProfileService } from './user-profile.service';

@Injectable({ providedIn: 'root' })
export class StatusService {
  public readonly allFilingStatus: StatusCommon[] = [
    StatusCommon.DRAFT_INCOMPLETE,
    StatusCommon.READY_TO_SEND,
    StatusCommon.SEC_CONSIDERING,
    StatusCommon.APPROVED,
    StatusCommon.CANCELLED,
    StatusCommon.WAIT_FOR_HARD_COPY,
    // full
    StatusCommon.FIRST_APPROVE,
    StatusCommon.FIRST_WAIT_FOR_HARD_COPY,
    StatusCommon.FIRST_DRAFT_INCOMPLETE,
    StatusCommon.FIRST_READY_TO_SEND,
    StatusCommon.FIRST_SEC_CONSIDERING,
  ];
  public readonly allListingStatus: StatusCommon[] = [
    StatusCommon.DRAFT_INCOMPLETE,
    StatusCommon.DRAFT_COMPLETE,
    StatusCommon.WAIT_TO_VERIFY,
    StatusCommon.REJECTED,
    StatusCommon.WAIT_TO_APPROVE,
    StatusCommon.APPROVED,
    StatusCommon.CANCELLED,
    StatusCommon['-'],
  ];
  constructor(
    private userProfileService: UserProfileService
  ) { }

  public filingStatus(isShort: boolean): StatusCommon[] {
    let statuses: StatusCommon[] = [];
    const userProfile = this.userProfileService.getUserProfile();
    if (userProfile.isDwSET) {
      statuses = statuses.concat([StatusCommon.SEC_CONSIDERING, StatusCommon.APPROVED, StatusCommon.FIRST_SEC_CONSIDERING]);
    }
    if (userProfile.isIssuer && isShort) {
      statuses = statuses.concat([StatusCommon.DRAFT_INCOMPLETE, StatusCommon.SEC_CONSIDERING, StatusCommon.APPROVED, StatusCommon.CANCELLED]);
    }
    return this.filterStatus(this.allFilingStatus, statuses);
  }

  public listingStatus(): StatusCommon[] {
    let statuses: StatusCommon[] = [];
    if (this.userProfileService.getUserProfile().isDwSET) {
      statuses = statuses.concat([StatusCommon.DRAFT_COMPLETE, StatusCommon.WAIT_TO_VERIFY, StatusCommon.WAIT_TO_APPROVE, StatusCommon.APPROVED, StatusCommon['-']]);
    }
    return this.filterStatus(this.allListingStatus, statuses);
  }

  private filterStatus(allStatuses: StatusCommon[], statuses: StatusCommon[]): StatusCommon[] {
    if (statuses.length > 0) {
      return allStatuses.filter(s => statuses.includes(s));
    }
    return allStatuses;
  }

}
