import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShelfIssuerApplicationComponent } from './shelf-issuer-application.component';
import { AuthorizedPersonGuard } from './authorized-person.guard';

const routes: Routes = [
  {
    path: '',
    component: ShelfIssuerApplicationComponent,
    children: [
      {
				path: 'list',
				loadChildren: './list/shelf-issuer-application-list.module#ShelfIssuerApplicationListModule',
			},
      {
        path: '',
        loadChildren: './authorized-person/authorized-person.module#AuthorizedPersonModule',
        canActivate: [
          AuthorizedPersonGuard
        ],
      },
      {
        path: ':id',
        loadChildren: './upload-document/upload-document.module#UploadDocumentModule',
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShelfIssuerApplicationDetailRoutingModule { }
