import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FilingThirtyFiveDwOneService } from '@app/core/services/api/filing-thirty-five-dw-one.service';
import { map } from 'rxjs/operators';
import { DOMAIN } from '@app/shared/app.constant';

@Injectable()
export class AuthorizedPersonGuard implements CanActivate {
  constructor(
    private filingService: FilingThirtyFiveDwOneService,
    private router: Router,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.filingService
      .validateCreate()
      .pipe(
        map(({ shelf, company }) => {
          if (!shelf || !company.validateValue) {
            this.router.navigate([`${DOMAIN.URL}shelf-issuer-application/list`]);
            return false;
          }
          return true;
        })
      )
  }
}
