interface NgxDatatablePage {
  limit: number;
  pageNumber: number;
  total: number;
}

export class NgxDatatablePageImp implements NgxDatatablePage {
  public limit = 10;
  public pageNumber = 0;
  public total = 0;

  constructor(args?: Partial<NgxDatatablePage>) {
    if (args) {
      this.limit = typeof args.limit === 'number' ? args.limit : 10;
      this.pageNumber = typeof args.pageNumber === 'number' ? args.pageNumber : 0;
      this.total = typeof args.total === 'number' ? args.total : 10;
    }
  }
}
