import { SubmitApplication } from '@app/shared/models/corebusiness/submit-application';
import { StatusCommon } from '@app/shared/models/corebusiness/status-common.enum';
import { Quota } from '@app/shared/models/corebusiness/quota';

export const checkEnableAction = (app: SubmitApplication, isSender = false, hasFeature = false, validateDocument?: boolean, validateDocumentInDW?: boolean, feature?: Quota) => {
  let enableSave: boolean = false;
  let enableConfirm: boolean = false;
  let enableCancel: boolean = false;
  let enableSend: boolean = false;
  let enableCancelDoc: boolean = false;
  let enableSign: boolean = false;
  switch (app.filingStatus) {
    case StatusCommon.DRAFT_INCOMPLETE: {
      enableSave = true
      enableConfirm = validateDocument && validateDocumentInDW
      enableCancel = true
      enableSend = false
      break
    }
    case StatusCommon.READY_TO_SEND: {
      enableSave = true
      enableConfirm = validateDocument && validateDocumentInDW
      enableCancel = true
      enableSend = isSender && validateDocument;
      break
    }
    case StatusCommon.WAIT_FOR_HARD_COPY: {
      enableCancel = true
      break
    }
    case StatusCommon.FIRST_APPROVE: {
      enableSave = true
      break;
    }
    case StatusCommon.FIRST_DRAFT_INCOMPLETE: {
      enableSave = true
      enableConfirm = hasFeature && validateDocument && validateDocumentInDW;
      break;
    }
    case StatusCommon.FIRST_READY_TO_SEND: {
      enableSave = true;
      enableSend = validateDocument;
      break;
    }
    case StatusCommon.APPROVED: {
      if (feature) {
        enableCancelDoc = feature.unlockAttachFileStatus === 'DRAFT';
        enableSign = isSender && feature.unlockAttachFileStatus === 'DRAFT';
      } else {
        enableCancelDoc = false;
        enableSign = false;
      }
      break;
    }
    default:
      enableSave = false
      enableConfirm = false
      enableCancel = false
      enableSend = false
      break
  }
  return {
    enableSave,
    enableConfirm,
    enableSend,
    enableCancel,
    enableCancelDoc,
    enableSign
  }
}
