import { Injectable } from '@angular/core';
import { Quota } from '@app/shared/models/corebusiness/quota';
import { isNullOrUndefined } from 'util';
import { SignDataType } from './sign-data-type';

@Injectable()
export class SecAdminSubmitApplicationDetailService {
  constructor() { }


  public getListedDwData(listedDw: Quota[]): { dwSymbol: string, rows: any[], selectList: any[], isSignIndex: boolean } {
    let dwSymbol = ''
    let rows = []
    let selectList = []
    let isSignIndex = false;

    listedDw.sort((dw1, dw2) => {
      let dw1Order = !dw1.underlying.dwUnderlying ? 'A' : dw1.underlying.dwUnderlying.includes('D') ? 'B' : dw1.underlying.dwUnderlying.includes('F') ? 'C' : 'A'
      let dw2Order = !dw2.underlying.dwUnderlying ? 'A' : dw2.underlying.dwUnderlying.includes('D') ? 'B' : dw2.underlying.dwUnderlying.includes('F') ? 'C' : 'A'
      if (dw1Order == dw2Order) {
        return dw1.underlying.code < dw2.underlying.code ? -1 : 1
      } else {
        return dw1Order < dw2Order ? -1 : 1
      }
    })

    listedDw.forEach((element, index) => {
      rows[index] = element
      rows[index]['underlyingCode'] = element.underlying.code
      if (element.underlying.type !== SignDataType.LISTED) {
        isSignIndex = true;
      }

      if (!isNullOrUndefined(element.dwRequestCode)) {
        dwSymbol = dwSymbol + element.dwRequestCode + (index === (listedDw.length - 1) ? '' : ',')
      }
      selectList[index] = {
        id: element.id,
        isUnderlyingFA: element.isUnderlyingFA
      }
    });
    return {
      dwSymbol,
      rows,
      selectList,
      isSignIndex
    }
  }
}
