import { Injectable } from '@angular/core';
import { UserProfileService } from '@app/core/services/user-profile.service';

@Injectable()
export class SecAdminSubmitApplicationService {
  constructor(
    private userProfileService: UserProfileService
  ) { }

  public getPath() {
    const user = this.userProfileService.getSessionUser();
    return user.isRegulator ? 'sec-admin/submit-application' : 'short';
  }
}
