import { Company } from './company';

export class AuthorizedProfile {
  id: number;
  company: Company;
  title: string;
  name: string;
  lastName: string;
  position: string;
  committee: boolean;
  authorized?: boolean;
  deleted: boolean;
  createdDate: Date;
  modifiedDate: Date;
  type: string;
  writtenAuthorityFileName?: any;
  writtenAuthorityUploadedFileName?: any;
  ceo?: boolean;
  cfo?: boolean;
  email: string;
}
