import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-shelf-issuer-application',
  templateUrl: './shelf-issuer-application.component.html',
  styleUrls: ['./shelf-issuer-application.component.scss']
})
export class ShelfIssuerApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
